import { h, FunctionalComponent } from 'preact';
import * as style from './sideAdvSx.scss';
import { Button } from '../Button/Button';
import { useState } from 'preact/hooks';
import { AdvSx } from '../types';

interface BooksLeftSideAdvancedSearchProps {
  onSubmit(advSx: AdvSx): void;
}

export const BooksLeftSideAdvancedSearch: FunctionalComponent<BooksLeftSideAdvancedSearchProps> = ({ onSubmit }) => {
  const [advSx, setAdvSx] = useState<AdvSx>({});
  return (
    <div class={style.sidebar}>
      <div class={style.toptitle}>Advanced Search</div>
      <div class={style.filterType}>
        <select
          value={advSx.rating || ''}
          onChange={(e: Event) => {
            const value = (e.srcElement as HTMLSelectElement).value;
            const advSxObj = { ...advSx };
            advSxObj.rating = value;
            setAdvSx(advSxObj);
            onSubmit(advSxObj);
          }}
        >
          <option>Content Rating:</option>
          <option value='G'>G</option>
          <option value='PG'>PG</option>
          <option value='PG13'>PG13</option>
          <option value='M'>M</option>
          <option value='R'>R</option>
        </select>
      </div>
      <div class={style.filterType}>
        {/* <div class={style.title}>Author</div>
        <div class={style.data}>
          <InputText
            class={style.formControl}
            placeholder='Author name'
            aria-label='AuthorName'
            // value={authorname}
            // onType={(authorname) => setSearchTerms(authorname)}
          />
        </div> */}
      </div>
      <div class={style.filterType}>
        <select
          value={advSx.genre || ''}
          onChange={(e: Event) => {
            const value = (e.srcElement as HTMLSelectElement).value;
            const advSxObj = { ...advSx };
            advSxObj.genre = value;
            setAdvSx(advSxObj);
            onSubmit(advSxObj);
          }}
        >
          <option>Genre:</option>
          <option value='adventure'>adventure</option>
          <option value='children'>children</option>
          <option value='war'>war</option>
        </select>
      </div>
      <div class={style.filterType}>
        {/* <div class={style.title}>ISBN</div>
        <div class={style.data}>
          <InputText class={style.formControl} placeholder={'ISBN number (10 or 13 digit)'} aria-label={'ISBN'} />
          value={isbn}
          onType={(isbn) => setSearchTerms(isbn)}
        </div> */}
      </div>
      <div class={style.filterType}>
        <select
          value={advSx.format || ''}
          onChange={(e: Event) => {
            const value = (e.srcElement as HTMLSelectElement).value;
            const advSxObj = { ...advSx };
            advSxObj.format = value;
            setAdvSx(advSxObj);
            onSubmit(advSxObj);
          }}
        >
          <option>Book Format:</option>
          <option value='h'>Hardcover</option>
          <option value='p'>Paperback</option>
          <option value='e'>eBook</option>
          <option value='a'>Audio</option>
          <option value='b'>Abridged</option>
        </select>
      </div>
      <div class={style.filterType}>
        <select
          value={advSx.stars || ''}
          onChange={(e: Event) => {
            const value = (e.srcElement as HTMLSelectElement).value;
            const advSxObj = { ...advSx };
            advSxObj.stars = value;
            setAdvSx(advSxObj);
            onSubmit(advSxObj);
          }}
        >
          <option>Star Rating:</option>
          <option value='1'>One Star</option>
          <option value='2'>Two Stars</option>
          <option value='3'>Three Stars</option>
          <option value='4'>Four Stars</option>
          <option value='5'>Five Stars</option>
        </select>
      </div>
      <div class={style.filterType}>
        <select
          value={advSx.tags || ''}
          onChange={(e: Event) => {
            const value = (e.srcElement as HTMLSelectElement).value;
            const advSxObj = { ...advSx };
            advSxObj.tags = value;
            setAdvSx(advSxObj);
            onSubmit(advSxObj);
          }}
        >
          <option>Tag:</option>
          <option value='botany'>botany</option>
          <option value='crime'>crime</option>
          <option value='humor'>humor</option>
          <option value='maritime'>maritime</option>
          <option value='mystery'>mystery</option>
        </select>
      </div>
      <Button
        onClick={() => {
          setAdvSx({});
          onSubmit({});
        }}
      >
        Reset
      </Button>
    </div>
  );
};
