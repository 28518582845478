/* eslint-disable max-len */
import { h, Component, VNode } from 'preact';
import * as style from './select.scss';
import { presentation } from '../../../utils/stringFormatting';

interface SelectProps {
  class?: string;
  value?: string | number;
  onChange: (e: string) => void;
  options: (
    | string
    | number
    | { key: string; value: string | number; title?: string }
    | any
  )[];
  disabled?: boolean;
  nodefault?: boolean;
}

export class Select extends Component<SelectProps> {
  public static defaultProps: SelectProps = {
    value: '',
    onChange: () => {},
    options: [],
    nodefault: false,
  };

  public render({
    value,
    onChange,
    options,
    disabled,
    nodefault,
    ...props
  }: SelectProps): VNode {
    return (
      <select
        class={`${style.select} ${props.class ? props.class : ''}`}
        value={value}
        disabled={disabled}
        onChange={(e: Event) =>
          onChange((e.srcElement as HTMLSelectElement).value)
        }
      >
        {nodefault === false && (
          <option value='' disabled>
            Please Select:
          </option>
        )}
        {options.map((option) => (
          <option
            value={
              typeof option === 'string' || typeof option === 'number'
                ? option
                : option.value
            }
            title={
              typeof option === 'string' || typeof option === 'number'
                ? option
                : 'title' in option
                ? option.title
                : option.key
            }
          >
            {presentation`${
              typeof option === 'string' || typeof option === 'number'
                ? option
                : option.key
            }`}
          </option>
        ))}
      </select>
    );
  }
}
