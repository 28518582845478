import { h, FunctionalComponent } from 'preact';
import * as style from './sideTagDrwr.scss';
import { useState, useEffect } from 'preact/hooks';
import { AdvSx } from '../types';

interface BooksSideTagDrawerProps {
  onSubmit(advSx: AdvSx): void;
}

export const BooksSideTagDrawer: FunctionalComponent<BooksSideTagDrawerProps> = ({ onSubmit }) => {
  const [advSx, setAdvSx] = useState<AdvSx>({});
  const [drawerOut, setDrawerOut] = useState(false);
  const msdClass = drawerOut ? `${style.sideTagDrwrWrap} ${style.allOut}` : style.sideTagDrwrWrap;
  const handleTagClick = (value: string) => {
    const advSxObj = { ...advSx };
    advSxObj.tags = value;
    setAdvSx(advSxObj);
    onSubmit(advSxObj);
  };

  //https://stackoverflow.com/questions/53090432/react-hooks-right-way-to-clear-timeouts-and-intervals
  useEffect(() => {
    let timer1 = setTimeout(() => setDrawerOut(true), 1200);
    return () => {
      // clear Timeout on component unmount (becomes ComponentWillUnmount)
      clearTimeout(timer1);
    };
  }, []); //MT array means useEffect will run only one time, at Component mount

  return (
    <div class={msdClass}>
      <div class={style.floatersMenu}>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('animals')}>
          <img src={'/img/tagButtons/tag_animals.png'} />
          <div class={style.tagText}>Tag: Animals</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('archaeology')}>
          <img src={'/img/tagButtons/tag_archeology.png'} />
          <div class={style.tagText}>Tag: Archaeology</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('architecture')}>
          <img src={'/img/tagButtons/tag_architecture.png'} />
          <div class={style.tagText}>Tag: Architecture</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('art')}>
          <img src={'/img/tagButtons/tag_art.png'} />
          <div class={style.tagText}>Tag: Art</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('cartoon')}>
          <img src={'/img/tagButtons/tag_cartoon.png'} />
          <div class={style.tagText}>Tag: Cartoon</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('christianity')}>
          <img src={'/img/tagButtons/tag_christianity.png'} />
          <div class={style.tagText}>Tag: Christianity</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('diy')}>
          <img src={'/img/tagButtons/tag_diy.png'} />
          <div class={style.tagText}>Tag: DIY</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('food')}>
          <img src={'/img/tagButtons/tag_food.png'} />
          <div class={style.tagText}>Tag: Food</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('maritime')}>
          <img src={'/img/tagButtons/tag_maritime.png'} />
          <div class={style.tagText}>Tag: Maritime</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('science')}>
          <img src={'/img/tagButtons/tag_science.png'} />
          <div class={style.tagText}>Tag: Science</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('sports')}>
          <img src={'/img/tagButtons/tag_sports.png'} />
          <div class={style.tagText}>Tag: Sports</div>
        </div>
        <div class={style.menuItemDiv} onClick={() => handleTagClick('war')}>
          <img src={'/img/tagButtons/tag_war.png'} />
          <div class={style.tagText}>Tag: War</div>
        </div>
      </div>
    </div>
  );
};
