import { h, FunctionalComponent } from 'preact';
import * as style from './style.scss';

interface LoadingProps {
  top?: number;
  left?: number;
}

export const Loading: FunctionalComponent<LoadingProps> = ({ top = 50, left = 50 }) => (
  <div class={style.lds_dual_ring} style={{ top: `${top}%`, left: `calc(${left}% - 32px)` }}>
    <br />
  </div>
);
