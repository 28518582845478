import { h, FunctionalComponent } from 'preact';
import * as style from './booksTLAS.scss';
import { InputText } from '../InputText/InputText';
import { Button } from '../Button/Button';
import { useState } from 'preact/hooks';

interface BooksTopLogoAndSearchProps {
  sxText?: string | undefined;
  onSubmit(sxText: string): void;
}

export const BooksTopLogoAndSearch: FunctionalComponent<BooksTopLogoAndSearchProps> = ({ sxText, onSubmit }) => {
  const [sxText2, setSxText] = useState(sxText ?? '');
  return (
    <div class={style.BtlasWrap}>
      <div class={style.logoSearchDiv}>
        <div class={style.logoDiv}>iberry</div>
        <div class={style.searchDiv}>
          <InputText class={style.inputSx} onType={setSxText} onConfirm={onSubmit} value={sxText2} />
          <Button class={style.btn2d} onClick={() => onSubmit(sxText2)}>
            Search
          </Button>
        </div>
      </div>
    </div>
  );
};
