import { h, FunctionalComponent, Fragment } from 'preact';
// import { useState } from 'preact/hooks';
import * as style from './register.scss';

interface RegisterProps {
  onDoLogin(): void;
  onClose(): void;
}

export const Register: FunctionalComponent<RegisterProps> = ({ onClose, onDoLogin }) => {
  return (
    <Fragment>
      <div class={style.overlay}></div>
      <div class={style.createAccountModal}>
        <div class={style.accmodTop}>
          <div class={style.accmodCloseX} onClick={onClose}>
            {' '}
            x{' '}
          </div>
          <div class={style.accmodTopText}>Create your account</div>
        </div>
        <div class={style.accmodBody}>
          <div class={style.accmodBodyLeft}>
            <div class={style.accmodFieldRow}>
              <div class={style.accmodFRLTitle}>Name*</div>
              <div class={style.accmodFRLInput}>
                <input id='fname' class={style.formControl} type='text' placeholder='First name' />
              </div>
              <div class={style.accmodFRLInput}>
                <input id='lname' class={style.formControl} type='text' placeholder='Last name' />
              </div>
            </div>
            <div class={style.accmodFieldRow}>
              <div class={style.accmodFRLTitle}>Email*</div>
              <div class={style.accmodFRLInput}>
                <input id='email1' class={style.formControl} type='text' placeholder='Email' />
              </div>
              <div class={style.accmodFRLInput}>
                <input id='email2' class={style.formControl} type='text' placeholder='Confirm Email' />
              </div>
            </div>

            <div class={style.accmodFieldRow}>
              <div class={style.accmodFRLTitle}>Password*</div>
              <div class={style.accmodFRLInput}>
                <input id='password1' class={style.formControl} type='password' placeholder='Password' />
              </div>
              <div class={style.accmodFRLInput}>
                <input id='password2' class={style.formControl} type='password' placeholder='Confirm Password' />
              </div>
            </div>

            <div class={`${style.accmodFieldRow} ${style.authorCheckboxDiv}`}>
              <div class={style.flexParent}>
                Author: <input class={style.authorCheckbox} type='checkbox' />
              </div>
              <div class={style.authorCheckboxQMark}>?</div>
              <div class={style.authorBlurb}>
                Check box if you are an{' '}
                <span class={style.authorSpan}>
                  author
                  <br />
                  with books rated on this site
                </span>
                .
              </div>
              <div class={style.accmodBtnRight}>
                <button class={style.ibutton}>Create account</button>
              </div>
            </div>
          </div>
          <div class={style.accmodBodyRight}>
            <div class={style.accmodBodyTitle}>Already have an account?</div>
            <div class={style.accmodFieldRow}>
              <a href='#' onClick={onDoLogin}>
                Login to your Account
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
