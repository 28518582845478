import { h, FunctionalComponent, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import * as style from './login.scss';
import { InputText } from '../../Components/InputText/InputText';
import { post } from '../../../utils/ajax';
import { UserData } from '../../store/store';
import { Button } from '../../Components/Button/Button';

interface LoginProps {
  onSuccess(data: UserData): void;
  onDoCreateAccount(): void;
  onClose(): void;
}

export const Login: FunctionalComponent<LoginProps> = ({ onSuccess, onClose, onDoCreateAccount }) => {
  // const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [pw, setPw] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);

  const handleLoginSubmit = async () => {
    // setLoading(true);
    try {
      const auth = await post<UserData>('/login', { id: username, pw });
      onSuccess(auth);
    } catch {
      setError(true);
    }
    // setLoading(false);
  };

  return (
    <Fragment>
      <div class={style.overlay}></div>
      <div class={style.loginModal}>
        <div class={style.logmodTop}>
          <div class={style.logmodCloseX} onClick={onClose}>
            {' '}
            x{' '}
          </div>
          <div class={style.logmodTopText}>Login</div>
        </div>
        <div class={style.logmodBody}>
          <div class={style.logmodBodyLeft}>
            <div class={style.logmodBodyTitle}>Login to your account</div>
            <div class={style.logmodFieldRow}>
              <div class={style.logmodFRLTitle}>Email:</div>
              <div class={style.logmodFRLInput}>
                <InputText
                  class={style.formControl}
                  placeholder='Username'
                  aria-label='Username'
                  value={username}
                  onType={(username) => setUsername(username)}
                />
              </div>
            </div>
            <div class={style.logmodFieldRow}>
              <div class={style.logmodFRLTitle}>Password:</div>
              <div class={style.logmodFRLInput}>
                <InputText
                  class={style.formControl}
                  type='password'
                  placeholder='Password'
                  aria-label='Password'
                  value={pw}
                  onType={(pw) => setPw(pw)}
                  onConfirm={handleLoginSubmit}
                />
              </div>
            </div>
            <div class={`${style.logmodFieldRow} ${style.forgotPasswordDiv}`}>
              <a href=''>Forgot Password?</a>
            </div>
            <div class={`${style.logmodFieldRow}`}>
              <Button class={style.ibutton} onClick={handleLoginSubmit}>
                Log in
              </Button>
            </div>
          </div>
          <div class={style.logmodBodyRight}>
            <div class={style.logmodBodyTitle}>Why create an account</div>
            <div class={style.logmodFieldRow}>
              <ul class={style.logmodUL}>
                <li>Approve ratings</li>
                <li>Enter books to be rated</li>
                <li>Rate books</li>
                <li>Correct ratings</li>
              </ul>
            </div>
            <div class={`${style.logmodFieldRow} ${style.logmodBtnRight}`}>
              <button class={style.ibutton} onClick={onDoCreateAccount}>
                Create account
              </button>
            </div>
          </div>
        </div>

        {error && (
          <div class={style.error}>
            The entered username/password combination is incorrect OR your trial period has ended.
          </div>
        )}
      </div>
    </Fragment>
  );
};
