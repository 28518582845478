import { h, FunctionalComponent } from 'preact';
import * as style from './style.scss';

interface VersionFooterProps {
  version: string;
}

export const VersionFooter: FunctionalComponent<VersionFooterProps> = ({ version }) => (
  <footer class={style.versionTime}>{version}</footer>
);
