import { h, VNode, JSX } from 'preact';
import * as style from './button.scss';

interface ButtonProps extends JSX.HTMLAttributes<HTMLButtonElement> {
  depressed?: boolean;
  type?: 'regular' | 'slim' | 'large';
  background?: 'regular' | 'red' | 'green' | 'bubble-green' | 'bubble-green-blink';
}

export const Button = ({
  depressed = false,
  type = 'regular',
  background = 'regular',
  onClick,
  children,
  ...props
}: ButtonProps): VNode => (
  <button
    {...props}
    data-background={background}
    data-size={type}
    onClick={onClick}
    class={`${style.button}
      ${depressed ? style.depressed : ''}
      ${props.class !== undefined ? props.class : ''}
      `}
  >
    {children}
  </button>
);
