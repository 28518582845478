import { h, FunctionalComponent } from 'preact';
import * as style from './allPagesTopControls.scss';
import { Link } from 'wouter-preact';
import { PrivLevelEnum, Authentication } from '../../store/store';
import { useContext } from 'preact/hooks';
//import { useState } from 'preact/hooks';
//import { Redirect } from 'wouter-preact';

interface AllPagesTopControlsProps {
  onUserAction(userAction: 'contact' | 'register' | 'login'): void;
}

export const AllPagesTopControls: FunctionalComponent<AllPagesTopControlsProps> = ({
  onUserAction,
}) => {
  const { authenticated, privLevel } = useContext(Authentication);
  return (
    <div class={style.allPagesTopControlsWrapper}>
      <div class={style.topDock}>
        <div onClick={() => onUserAction('contact')}>Contact</div>
        <span>&nbsp;/&nbsp;</span>
        <div onClick={() => onUserAction('register')}>Register</div>
        <span>&nbsp;/&nbsp;</span>
        {authenticated ? (
          <a href='/logoff'>Logout</a>
        ) : (
          <div onClick={() => onUserAction('login')}>Login</div>
        )}
      </div>
      <div class={style.topDecorWrap}>
        <div class={style.bgImage}>
          <img src='/img/decor.png' />
        </div>
      </div>
      <div class={style.topbtnWrap}>
        <div class={style.floatersMenu}>
          <div class={`${style.menuItemDiv} ${style.menuHome}`}>
            <div class={style.menuImg}>
              <Link href='/'>
                <img src={'/img/menuButtons/home.png'} />
              </Link>
            </div>
            <div class={style.menuLbl}>
              <br />
              Home
            </div>
          </div>
          <div class={`${style.menuItemDiv} ${style.menuBooks}`}>
            <div class={style.menuImg}>
              <Link href='/books'>
                <img src={'/img/menuButtons/books.png'} />
              </Link>
            </div>
            <div class={style.menuLbl}>
              <br />
              Books
            </div>
          </div>
          <div class={`${style.menuItemDiv} ${style.menuKids}`}>
            <div class={style.menuImg}>
              <Link href='/kids'>
                <img src={'/img/menuButtons/kids.png'} />
              </Link>
            </div>
            <div class={style.menuLbl}>
              <br />
              <br />
              Kids
            </div>
          </div>
          <div class={`${style.menuItemDiv} ${style.menuForms}`}>
            <div class={style.menuImg}>
              <Link href='/forms'>
                <img src={'/img/menuButtons/forms.png'} />
              </Link>
            </div>
            <div class={style.menuLbl}>
              <br />
              Forms
            </div>
          </div>
          <div class={`${style.menuItemDiv} ${style.menuRateSys}`}>
            <div class={style.menuImg}>
              <Link href='/ratingsystem'>
                <img src={'/img/menuButtons/ratesys.png'} />
              </Link>
            </div>
            <div class={style.menuLbl}>Rating System</div>
          </div>
          <div class={`${style.menuItemDiv} ${style.menuRateBook}`}>
            {authenticated ? (
              <div class={style.menuImg}>
                <Link href='/ratebook'>
                  <img src={'/img/menuButtons/ratebook.png'} />
                </Link>
              </div>
            ) : (
              <div class={style.menuImg} onClick={() => onUserAction('login')}>
                <img src={'/img/menuButtons/ratebook.png'} />
              </div>
            )}
            <div class={style.menuLbl}>
              <br />
              Rate Books
            </div>
          </div>
          <div class={`${style.menuItemDiv} ${style.menuMember}`}>
            <div class={style.menuImg}>
              <Link href='/members'>
                <img src={'/img/menuButtons/memb.png'} />
              </Link>
            </div>
            <div class={style.menuLbl}>
              <br />
              <div class={style.menuLblSmall}>Members</div>
            </div>
          </div>
          <div class={`${style.menuItemDiv} ${style.menuAuthor}`}>
            <div class={style.menuImg}>
              <Link href='/authors'>
                <img src={'/img/menuButtons/author.png'} />
              </Link>
            </div>
            <div class={style.menuLbl}>
              <br />
              Author
            </div>
          </div>
          <div class={`${style.menuItemDiv} ${style.menuProfile}`}>
            {authenticated ? (
              <div class={style.menuImg}>
                <Link href='/profile'>
                  <img src={'/img/menuButtons/profile.png'} />
                </Link>
              </div>
            ) : (
              <div class={style.menuImg} onClick={() => onUserAction('login')}>
                <img src={'/img/menuButtons/profile.png'} />
              </div>
            )}
            <div class={style.menuLbl}>
              <br />
              Profile
            </div>
          </div>
          {console.log(
            `authenticated: ${authenticated} -- privLevel: ${privLevel}`
          )}
          {authenticated !== undefined &&
            authenticated &&
            privLevel !== undefined &&
            privLevel == PrivLevelEnum.siteAdmin && (
              <div class={`${style.menuItemDiv} ${style.menuAdmin}`}>
                <div class={style.menuImg}>
                  <Link href='/admin'>
                    <img src={'/img/menuButtons/admin.png'} />
                  </Link>
                </div>
                <div class={style.menuLbl}>
                  <br />
                  ADMIN
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
