import { h, FunctionalComponent } from 'preact';
import * as style from './resultsBar.scss';
import { useState, useContext } from 'preact/hooks';
import { BtnAction } from '../types';
import { Pagination } from '../../pagination/paginationStore';

interface BooksPageResultsBarProps {
  numBooks?: number;
  resultBarBtn(btnAction: BtnAction): void;
  onPageChange(currentPage: number): void;
  onItemsPPChange(itemsPerPage: number): void;
}

export const BooksPageResultsBar: FunctionalComponent<BooksPageResultsBarProps> = ({
  numBooks,
  resultBarBtn,
  onPageChange,
  onItemsPPChange,
}) => {
  const foundMsg = numBooks !== undefined ? `Displaying ${numBooks} books` : '';
  const [sortDir, setSortDir] = useState('asc');
  const [showCoverImage, setShowCoverImage] = useState(true);
  const { currentPage, itemsPerPage } = useContext(Pagination); //creates the consumer
  const totalPages = numBooks !== undefined ? Math.ceil(numBooks / (itemsPerPage || 15)) : 1;
  const maxPageSelectors = 3;
  const handleChangePage = (page: number) => {
    const laPage = page < 1 ? 1 : page > totalPages ? totalPages : page;
    onPageChange(laPage);
  };
  const setPages = (currentPage: number) => {
    const pages = paginate(numBooks || 1, currentPage, itemsPerPage, maxPageSelectors);
    return pages.map((page: number) => (
      <li class={page === currentPage ? style.current : undefined} onClick={() => handleChangePage(page)}>
        {page}
      </li>
    ));
  };
  const paginate = (totalItems: number, currentPage = 1, pageSize = 10, maxPageSelectors = 5): number[] => {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number;
    let endPage: number;
    if (totalPages <= maxPageSelectors) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPageSelectorsBeforeCurrentPage = Math.floor(maxPageSelectors / 2);
      const maxPageSelectorsAfterCurrentPage = Math.ceil(maxPageSelectors / 2) - 1;
      if (currentPage <= maxPageSelectorsBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPageSelectors;
      } else if (currentPage + maxPageSelectorsAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPageSelectors + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPageSelectorsBeforeCurrentPage;
        endPage = currentPage + maxPageSelectorsAfterCurrentPage;
      }
    }
    // create an array of pages to ng-repeat in the pager control
    return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
  };

  return (
    <div class={style.barWrap}>
      <div class={style.foundSxResults}>{foundMsg}</div>
      <div class={style.paginationDiv}>
        {numBooks !== undefined && numBooks > itemsPerPage && (
          <ul class={style.pageList}>
            <li onClick={() => handleChangePage(1)}>First</li>
            <li onClick={() => handleChangePage(currentPage - 1)}>&laquo;</li>
            {setPages(currentPage)}
            <li onClick={() => handleChangePage(currentPage + 1)}>&raquo;</li>
            {totalPages && <li onClick={() => handleChangePage(totalPages)}>Last</li>}
          </ul>
        )}
      </div>
      <div class={style.controlsDiv}>
        <div class={style.selectDiv}>
          <select
            value={itemsPerPage}
            onChange={(e: Event) => {
              const el = e.srcElement as HTMLSelectElement;
              const value = el.value;
              console.log({ value });
              onItemsPPChange(+value);
            }}
          >
            <option value=''>Per Page</option>
            <option value='2'>2</option>
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='15'>15</option>
            <option value='25'>25</option>
            <option value='35'>35</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='500'>500</option>
          </select>
        </div>
        <img
          aria-title='Sort Z-to-A'
          src='img/widgets/zed.png'
          onClick={() => {
            const newSortDir = sortDir === 'asc' ? 'desc' : 'asc';
            setSortDir(newSortDir);
            resultBarBtn({ action: 'sortdir', param: newSortDir });
          }}
        />
        <img
          aria-title='Reset book list'
          src='img/widgets/reset.png'
          onClick={() => resultBarBtn({ action: 'reset' })}
        />
        <img
          aria-title='Show/Hide images'
          title={showCoverImage ? 'Hide book cover images' : 'Show book cover images'}
          src={showCoverImage ? 'img/widgets/picview.png' : 'img/widgets/listview.png'}
          onClick={() => {
            resultBarBtn({ action: 'showBookCover', param: !showCoverImage });
            setShowCoverImage(!showCoverImage);
          }}
        />
      </div>
    </div>
  );
};
