import { h, Component, Fragment } from 'preact';
// import * as style from './style.scss';
// import { Router, Route } from 'wouter-preact';
import { VersionFooter } from './Components/VersionFooter/VersionFooter';
import { VisibleModal } from './Components/types';
import { Login } from './Components/Login/Login';
import { Register } from './Components/Register/Register';
import { MainSideDrawer } from './Routes/HomePage/MainSideDrawer/MainSideDrawer';
import { Router, Route, useLocation } from 'wouter-preact';
import { Books } from './Routes/Books/Books';
import { Wrapper } from './Components/Wrapper/Wrapper';
import { Kids } from './Routes/Kids/Kids';
import { Forms } from './Routes/Forms/Forms';
import { RatingSystem } from './Routes/RatingSystem/RatingSystem';
import { RateBook } from './Routes/RateBook/RateBook';
import { Members } from './Routes/Members/Members';
import { Authors } from './Routes/Authors/Authors';
import { Profile } from './Routes/Profile/Profile';
import { Authentication, UserData, PrivLevelEnum } from './store/store';
import { MainWelcomeBlurb } from './Routes/HomePage/MainWelcomeBlurb/MainWelcomeBlurb';
import { MainWelcomeSearch } from './Routes/HomePage/MainWelcomeSearch/MainWelcomeSearch';
import { MainPageOverlay } from './Routes/HomePage/MainPageOverlay/MainPageOverlay';
import { get } from '../utils/ajax';
import { BookWithRatingDisplay } from './Routes/BookWithRatingDisplay/BookWithRatingDisplay';
import { AllPagesTopControls } from './Components/AllPagesTopControls/AllPagesTopControls';
import { Admin } from './Routes/Admin/Admin';

interface AppState {
  error?: string;
  sxText?: string;
  authenticated?: boolean;
  username?: string;
  privLevel: PrivLevelEnum;
  visibleModal?: VisibleModal;
}

export class App extends Component<{}, AppState> {
  public render(
    {},
    { authenticated, username, privLevel, visibleModal, sxText }: AppState
  ) {
    // const url = window.location.pathname;
    return (
      <Fragment>
        <Authentication.Provider value={{ username, privLevel, authenticated }}>
          <Router>
            <Route path='/'>
              <MainPageOverlay />
              <MainSideDrawer onUserClick={this.handleMainSideDrawerClick} />
              <MainWelcomeBlurb />
              <MainWelcomeSearch onSubmit={this.handleWelcomeSxSubmit} />
            </Route>
            <Route path='/books'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <Books sxText={sxText} />
              </Wrapper>
            </Route>
            <Route path='/book/:bid'>
              {/* Below func has to be the ONLY thing inside <Route> */}
              {(params) => (
                <Wrapper
                  bg={'rgba(0,0,0,0.3)'}
                  style={{ 'min-height': '100vh', height: 'fit-content' }}
                >
                  <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                  <BookWithRatingDisplay
                    bookID={+params.bid}
                    doClose={this.handleViewBookRatingClose}
                  />
                </Wrapper>
              )}
            </Route>
            <Route path='/kids'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <Kids />
              </Wrapper>
            </Route>
            <Route path='/forms'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <Forms />
              </Wrapper>
            </Route>
            <Route path='/ratingsystem'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <RatingSystem />
              </Wrapper>
            </Route>
            <Route path='/ratebook'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <RateBook />
              </Wrapper>
            </Route>
            <Route path='/members'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <Members />
              </Wrapper>
            </Route>
            <Route path='/authors'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <Authors />
              </Wrapper>
            </Route>
            <Route path='/profile'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <Profile />
              </Wrapper>
            </Route>
            <Route path='/admin'>
              <Wrapper
                bg={'rgba(0,0,0,0.3)'}
                style={{ 'min-height': '100vh', height: 'fit-content' }}
              >
                <AllPagesTopControls onUserAction={this.handleTopDockClick} />
                <Admin doSave={this.handleAdminSave} />
              </Wrapper>
            </Route>
          </Router>
        </Authentication.Provider>

        {visibleModal === 'login' && (
          <Login
            onSuccess={this.handleLogin}
            onDoCreateAccount={() =>
              this.setState({ visibleModal: 'register' })
            }
            onClose={() => this.setState({ visibleModal: undefined })}
          />
        )}
        {visibleModal === 'register' && (
          <Register
            onDoLogin={() => this.setState({ visibleModal: 'login' })}
            onClose={() => this.setState({ visibleModal: undefined })}
          />
        )}
        {/* {visibleModal === 'contact' && <Contact />} */}
        <VersionFooter version='v.005261502' />
      </Fragment>
    );
  }

  public componentDidMount() {
    get<UserData>('/is-auth')
      .then(({ username, authenticated, privLevel }) => {
        this.setState({ username, authenticated, privLevel });
      })
      .catch(() => {
        this.setState({
          error:
            'We encountered a problem getting current books. Please try again later.',
        });
      });
  }
  private handleWelcomeSxSubmit = (sxText: string) => {
    console.log('handleWelcomeSxSubmit');
    this.setState({ sxText });
  };

  private handleMainSideDrawerClick = () => {
    this.setState({});
  };

  private handleLogin = ({ username, authenticated }: UserData) => {
    this.setState({ username, authenticated, visibleModal: undefined });
  };

  private handleTopDockClick = (visibleModal: VisibleModal) => {
    this.setState({ visibleModal });
  };
  private handleViewBookRatingClose = () => {
    // @ts-ignore
    const [_location, setLocation] = useLocation();
    setLocation(`/books`);
  };
  private handleAdminSave = () => {
    console.log('This section does nothing yet');
  };
}
