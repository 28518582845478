import { h, FunctionalComponent } from 'preact';
import * as style from './style.scss';

interface WrapperProps {
  bg?: string;
  pad?: string;
  style?: { [key: string]: string };
  class?: string;
}

export const Wrapper: FunctionalComponent<WrapperProps> = ({
  children,
  style: bob,
  class: fred,
  bg = 'transparent',
  pad = '0',
}) =>
  // prettier-ignore
  <div
    style={{ ...bob, background: bg, padding: pad }}
    class={`${style.wrapper} ${fred === undefined ? '' : fred}`}>
    {children}
  </div>;
