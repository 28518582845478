import { h, Component } from 'preact';
import * as style from './booksResults.scss';
import { Wrapper } from '../Wrapper/Wrapper';
import { BookTitle } from '../types';
// import { get } from '../../../utils/ajax';
import { Book } from '../../Routes/Books/Book';
import { Loading } from '../Loading/Loading';
import { Pagination, PaginationData } from '../../pagination/paginationStore';
import { useLocation } from 'wouter-preact';

/* Called by Books.tsx to display the List of Books. Uses Book.tsx to display each book. */

interface BooksResultsProps {
  books?: BookTitle[];
  showBookCover: boolean;
}
interface BooksResultsState {
  error?: string;
  filters?: string;
}

export class BooksResults extends Component<
  BooksResultsProps,
  BooksResultsState
> {
  public static contextType = Pagination; //context is now accessible as this.context
  public render(
    { books, showBookCover }: BooksResultsProps,
    { error, filters }: BooksResultsState
  ) {
    const { currentPage, itemsPerPage } = this.context as PaginationData; //creates the consumer
    const lastItem = itemsPerPage * currentPage - 1;
    const firstItem = lastItem - itemsPerPage;
    const booksOnPage = books?.filter(
      (_child, idx) => idx >= firstItem && idx < lastItem
    );
    return (
      <Wrapper bg={'white'} pad={'10px'}>
        {books === undefined && <Loading />}
        {books !== undefined && books.length > 0 ? (
          <div class={style.books}>
            {booksOnPage?.map((book) => {
              return (
                <Book
                  book={book}
                  showBookCover={showBookCover}
                  onViewBookRating={this.handleViewBookRating}
                />
              );
            })}
          </div>
        ) : (
          'No results found.'
        )}
      </Wrapper>
    );
  }

  private handleViewBookRating = (bid: number) => {
    sessionStorage.setItem('pagination', this.context.currentPage);
    // @ts-ignore
    const [_location, setLocation] = useLocation();
    setLocation(`/book/${bid}`);
  };
}
